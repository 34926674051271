<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t("Menus.NewButton", {}, { locale: this.$store.state.activeLang })
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="menuList"
    @onTranslationButtonClick="onTranslationButtonClick"
  />
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="parentPublicId"
    :formulaName="formulaName"
    :keyName="keyName"
    :keyItemPublicId="keyItemPublicId"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "MenuList",
  data() {
    return {
      keyTypeId: 6,
      parentPublicId: "",
      keyItemPublicId: "",
      formulaName: "",
      keyName: "",
      menuList: [],
      gridSettings: {
        action: "MenuList",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "menuId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        isGatewayRequest: true,

        serialNoLink: `#/Menu/ListItem/`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-MenuDelete",

        autoOrderProcess: false,

        buttons: [
          {
            name: "detail",
            cssClass: "btn-primary",
            iconClass: "bi-list-task",
            routeButton: true,
            routeObj: {
              name: "MenuListItem",
              params: {
                menuId: "",
              },
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "MenuEdit",
              params: {
                menuId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Menus.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.IsSideBarActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isSideBarActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.IsDocumentActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isDocumentActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.IsChatActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isChatActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.IsDelegationActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isDelegationActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    getMenuList() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-MenuList"))
        .then((response) => {
          this.menuList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onTranslationButtonClick(rowData) {
      this.keyItemPublicId = rowData.publicId;
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      this.parentPublicId = rowData.name;
      showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    this.getMenuList();
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translate",
        cssClass: "btn-primary btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
  },
};
</script>
